import gql from 'graphql-tag';

const MyMultipass = gql`
  query UserMultipass($returnTo: String) {
    myMultipass(returnTo: $returnTo) {
      token
      url
    }
  }
`;

export default MyMultipass;
