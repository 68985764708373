import React, { useEffect } from "react"
import { useQuery } from "@apollo/react-hooks"
import { Box, Heading } from "grommet"

import MY_MULTIPASS from "../queries/myMultipass"

const MultipassLogin = () => {
  const { data, loading } = useQuery(MY_MULTIPASS)

  useEffect(() => {
    if (data && data.myMultipass && data.myMultipass.url) {
      window.location = data.myMultipass.url
    }
  }, [data])

  return (
    <Box>
      <Heading level={2}>
        {loading
          ? "Logging you in to Industry by Backdrop..."
          : "Redirecting you to Backdrop..."}
      </Heading>
    </Box>
  )
}
export default MultipassLogin
