import React, { useState } from "react"
import queryString from "query-string"
import { Box } from "grommet"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import withLocation from "../../../components/withLocation"
import MultipassLogin from "../../../components/MultipassLogin"
import OAuthLogin from "../../../components/OAuthLogin"

const GoogleAuthCallbackPage = ({ search }) => {
  const params = queryString.parse(search)
  const [jwt, setJwt] = useState(null)

  return (
    <Layout>
      <SEO title="Logging you in to Industry by Backdrop" />
      <Box
        align="center"
        justify="center"
        pad="large"
        margin={{ vertical: "large" }}
      >
        {jwt ? (
          <MultipassLogin />
        ) : (
          <OAuthLogin
            provider="google"
            accessToken={params.access_token}
            onJwt={setJwt}
          />
        )}
      </Box>
    </Layout>
  )
}

export default withLocation(GoogleAuthCallbackPage)
