import React, { useEffect } from "react"
import fetch from "isomorphic-fetch"
import { Box, Heading } from "grommet"
import store from "store"

const OAuthLogin = ({ provider, accessToken, onJwt }) => {
  const authEndpoint = process.env.GATSBY_GRAPHQL_ENDPOINT.replace(
    "graphql",
    `auth/${provider}/callback`
  )
  useEffect(() => {
    const response = fetch(`${authEndpoint}?access_token=${accessToken}`)
      .then(response => {
        return response.json()
      })
      .then(data => {
        store.set("backdrop-api-token", data.jwt)
        onJwt(data.jwt)
      })
  }, [accessToken, authEndpoint, onJwt])

  return (
    <Box>
      <Heading level={2}>Logging you in to Industry by Backdrop...</Heading>
    </Box>
  )
}
export default OAuthLogin
